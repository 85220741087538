<template>
	<div class="leftNav">
		<div class="leftNavContainer">
			<div class="title">All Products</div>
			<div class="nav">
				<ul class="list">
					<li class="item" v-for="(item,index) in menu" :key="index">
						<RouterLink :to="'/product?id='+item.id" class="li-title parent1 active active1" @click="pronav(item.id)" @click.stop="showSubNav($event)" :data-isShowSubNav="true">
							<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
								<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
							</svg>
							<span class="li-text">{{ item.catname }}</span>
						</RouterLink>
						<ul class="subList">
							<li v-for="(items,indexs) in item.child" :key="indexs">
								<RouterLink :to="'/product?id='+items.id" class="li-title" @click="pronav(items.id)"  @click.stop="showSubNav($event)" :data-isShowSubNav="true">
									<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
										<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
									</svg>
									<span class="li-text">{{ items.catname }}</span>
								</RouterLink>
								<ul class="subList">
									<li v-for="(itemss,indexss) in items.child" :key="indexss">
										<RouterLink :to="'/product?id='+itemss.id" @click="pronav(itemss.id)" class="li-title">
											<svg></svg>
											<span class="li-text">{{ itemss.catname }}</span>
										</RouterLink>
									</li>
								</ul>
							</li>
							 
						</ul>
					</li>
					 
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery';
	export default {
		name: 'leftNav',
		props: {
			msg: String
		},
		data() {
			return {
				menu:JSON.parse(localStorage.getItem('prolist')),
				showSubNav(event){
					let parent = $(event.target).parents(".li-title")
					parent.parents(".list").find(".li-title").removeClass("active1")
					parent.addClass("active1")
					if($(event.target).hasClass("li-text")){
						parent.addClass("active")
					}else{
						if(parent.hasClass("active")){
							parent.removeClass("active")
							parent.find(".li-title").removeClass("active")
						}else{
							parent.addClass("active")
						}
					}
					
				}
			}
		},
		components: {
			
		},
		methods: {
			pronav(index) { 
				this.$emit('pronavlink', index);
			}
		}
	}
</script>

<style lang="less">
	.leftNav{
		width: 400px;
		    min-width: 300px;
		    margin-right: 50px;
	}
	.leftNavContainer {
		height: 100%;
		margin: 20px;

		.title {
			font-size: 25px;
			margin-left: 15px;
			padding-left: 4px;
			line-height: 37px;
			letter-spacing: 0.00938em;

			&:hover {
				background-color: rgba(0, 0, 0, 0.04);
			}
		}

		.nav .li-title {
			display: flex;
			cursor: pointer;
			font-size: 1rem;
			line-height: 1.5;
			letter-spacing: 0.00938em;
			color: #000;
		}
		.nav .li-title.parent1 {
			font-weight: 700;
		}
		.nav .li-title .li-text{
			flex: 1;
			padding-left: 4px;
		}
		.nav .li-title .li-text:hover{
			background-color: rgba(0, 0, 0, 0.04);
		}
		.nav .li-title.active1 .li-text{
			background-color: rgba(63, 81, 181, 0.08) !important;
		}
		.nav .li-title.active svg{
			transform: rotate(90deg);
		}
		.nav .li-title svg {
			font-size: 18px;
			fill: currentColor;
			width: 1em;
			height: 1em;
			display: inline-block;
			transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			flex-shrink: 0;
			user-select: none;
			transition: all .6s;
		}
		.subList{
			margin-left: 17px;
			display: none;
		}
		.nav .li-title.active+.subList{
			display: block;
		}
	}
	@media (max-width:1024px) {
		.leftNav{
			max-width: 100%;
			margin-right: 0;
		}
	}
	@media (max-width:500px) {
		.leftNavContainer{
			margin: 0 10px 10px;
		}
	}
</style>