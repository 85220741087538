<template>
	<div class="contactContent">
		<leftNav></leftNav>
		<div class="r">
			<div class="r-l">
				<div class="content">
					<h1 class="title">{{ info.title }}</h1>
					<div class="des">
						{{ info.msg }}
					</div>
					<form action="">
						<div class="inputBox">
							<label>Name</label>
							<div class="input">
								<input type="text" name="name" v-model="name" placeholder="" @focus="inputFocus($event)"
									@blur="inputBlur($event)">
							</div>
						</div>
						<div class="inputBox">
							<div class="input">
								<div class="selectBtn">
									<a-dropdown>
										<template #overlay>
											<a-menu @click="handleMenuClick">
												<a-menu-item :key="index" v-for="(item, index) in info.tel">
													<img :src="item.image" alt="">
													{{ item.title }}
												</a-menu-item>
											</a-menu>
										</template>
										<!-- <a-button>
									       <img src="../assets/qizhi.png" alt=""> +86
									        <DownOutlined />
									      </a-button> -->
										<a class="ant-dropdown-link" @click.prevent>
											<img src="../assets/qizhi.png" alt=""> +86
										</a>
									</a-dropdown>
								</div>
								<input type="tel" placeholder="" v-model="mobile" @focus="inputFocus($event)"
									@blur="inputBlur($event)">
							</div>
						</div>
						<div class="inputBox">
							<label>Email Address</label>
							<div class="input">
								<input type="text" placeholder="" v-model="email" @focus="inputFocus($event)"
									@blur="inputBlur($event)">
							</div>
						</div>
						<div class="inputBox textareaBox">
							<label>Message</label>
							<div class="input">
								<textarea @focus="inputFocus($event)" @blur="inputBlur($event)"
									v-model="message"></textarea>
							</div>
						</div>
						<div class="submit" @click="submit">SUBMIT</div>

					</form>
				</div>
			</div>
			<div class="r-r">
				<h1 class="title">{{ info.p1_title }}</h1>
				<iframe title="map" :src="info.p1_map" width="90%" height="60%" frameborder="0" allowfullscreen=""
					aria-hidden="false" tabindex="0" style="border: 0px;"></iframe>
				<ul class="msg">
					<li>
						<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
							<path
								d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z">
							</path>
						</svg>
						<span>{{ info.p1_address }}</span>
					</li>
					<li>
						<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
							<path
								d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z">
							</path>
						</svg>
						<span>{{ info.p1_tel }}</span>
					</li>
					<li>
						<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
							<path
								d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z">
							</path>
						</svg>
						<span>{{ info.p1_fax }}</span>
					</li>
					<li>
						<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
							<path
								d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z">
							</path>
						</svg>
						<span>{{ info.p1_email }}</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery';
import leftNav from './leftNav.vue'
import {
	UserOutlined,
	DownOutlined
} from '@ant-design/icons-vue';
// import type { MenuProps } from 'ant-design-vue';
import { contact } from "@/utils/api"
import { notification } from 'ant-design-vue'
import { form } from "@/utils/api"
export default {
	name: 'about',
	data() {
		return {
			info: [],
			name: '',
			code: '+86',
			mobile: '',
			email: '',
			message: ''
		}
	},
	components: {
		leftNav,
	},
	mounted() {
		this.getdate()
	},
	methods: {
		getdate() {
			contact().then(res => {
				this.$data.info = res.data;
			})
		},
		submit() {
			let param = {
				name: this.$data.name,
				code: this.$data.code,
				mobile: this.$data.mobile,
				email: this.$data.email,
				message: this.$data.message,
			};
			if (param.name == '') { 
				this.alert('Please enter your name', 'error');
				return false;
			}
			if (param.mobile == '') { 
				this.alert('Please enter your mobile', 'error');
				return false;
			}
			if (param.email == '') { 
				this.alert('Please enter your e-mail', 'error');
				return false;
			}
			if (param.message == '') { 
				this.alert('Please enter message', 'error');
				return false;
			}
			form(param).then(res => { 
				if (res.code == 1) {
					this.alert('The submission was successful');
					this.$data.name = '';
					this.$data.mobile = '';
					this.$data.email = '';
					this.$data.message = '';
				} 
			})
		},
		inputFocus(event) {
			$(event.target).parents(".inputBox").addClass("active").addClass("active1").siblings().removeClass(
				"active")
			// .removeClass("active1")
		},
		inputBlur(event) {
			$(event.target).parents(".inputBox").removeClass("active")
			let val = $(event.target).val()
			if (val.length == 0 || this.isNull(val)) {
				$(event.target).parents(".inputBox").removeClass("active1")
			}

		},
		isNull(str) {
			if (str == "") return true;
			var regu = "^[ ]+$";
			var re = new RegExp(regu);
			return re.test(str);
		},
		handleMenuClick(e) {
			console.log(e.key)
		},
		alert(msg, status = 'success') {
			notification[status]({ message: msg, description: '' })
		}
	}
}
</script>

<style lang="less">
.contactContent {
	display: flex;
	// justify-content: space-between;
	padding-top: 20px;
	padding-bottom: 12px;

	.r {
		flex: 1;
		display: flex;
		justify-content: space-between;

		&>div {
			width: 500px;
			height: 500px;
			margin: 50px auto 50px auto;
			display: table-cell;
			text-align: center;
			vertical-align: middle;
			overflow: hidden;
			box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
			border-radius: 4px;
			color: rgba(0, 0, 0, 0.87);
			transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			background-color: #fff;

		}

		.title {
			margin: 5px 0px;
		}

		.r-l {
			.content {
				width: 90%;
				height: 80%;
				display: inline-block;

				.des {
					width: 80%;
					margin: 10px auto auto auto;
				}

				form {
					.inputBox {
						position: relative;
						width: 80%;
						margin: 10px auto auto auto;
						border: 0;
						display: inline-flex;
						padding: 0;
						position: relative;
						min-width: 0;
						flex-direction: column;
						vertical-align: top;

						label {
							color: rgba(0, 0, 0, 0.54);
							padding: 0;
							font-size: 1rem;
							font-weight: 400;
							line-height: 1;
							letter-spacing: 0.00938em;
							display: block;
							top: 0;
							left: 0;
							position: absolute;
							transform: translate(0, 24px) scale(1);
							transform-origin: top left;
							transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

							&+.input {
								margin-top: 16px;
							}
						}

						.input {
							color: rgba(0, 0, 0, 0.87);
							cursor: text;
							display: inline-flex;
							position: relative;
							font-size: 1rem;
							box-sizing: border-box;
							align-items: center;
							font-family: "Roboto", "Helvetica", "Arial", sans-serif;
							font-weight: 400;
							line-height: 1.1876em;
							letter-spacing: 0.00938em;

							&:before {
								left: 0;
								right: 0;
								bottom: 0;
								content: "\00a0";
								position: absolute;
								transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
								border-bottom: 1px solid rgba(0, 0, 0, 0.42);
								pointer-events: none;
							}

							&:hover {
								&::before {
									border-width: 2px;
									border-color: #000;
								}
							}

							&::after {
								left: 0;
								right: 0;
								bottom: 0;
								content: "";
								position: absolute;
								transform: scaleX(0);
								transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
								border-bottom: 2px solid #3f51b5;
								pointer-events: none;
							}

							textarea,
							input {
								color: currentColor;
								width: 100%;
								border: 0;
								height: 1.1876em;
								margin: 0;
								display: block;
								padding: 6px 0 7px;
								min-width: 0;
								background: none;
								box-sizing: content-box;
								// animation-name: mui-auto-fill-cancel;
								letter-spacing: inherit;
								animation-duration: 10ms;
								outline: none;
								-webkit-tap-highlight-color: transparent;
								font-size: 1rem;
							}

							.selectBtn {
								margin-bottom: 5px;

								.ant-dropdown-link {
									display: flex;
									align-items: center;
									cursor: pointer;
								}

								img {
									width: 30px;
									margin-right: 10px;
								}

								span {
									font-size: 1rem;
								}
							}
						}

						&.active {
							label {
								color: #3f51b5;
							}

							.input {
								&::after {
									transform: scaleX(1);
								}
							}
						}

						&.active1 {
							label {
								transform: translate(0, 1.5px)scale(0.75);
							}
						}

						&.textareaBox {
							transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
							background-color: rgba(0, 0, 0, 0.09);
							border-top-left-radius: 4px;
							border-top-right-radius: 4px;

							label {
								transform: translate(12px, 26px) scale(1);
								pointer-events: none;
							}

							.input {
								margin-top: 0;
								padding: 27px 12px 10px;

								textarea {
									height: 114px;
									resize: none;
									padding: 0;
								}
							}

							&.active1 {
								label {
									transform: translate(12px, 10px)scale(0.75);
								}
							}

						}
					}

					.submit {
						width: 30%;

						line-height: 37px;
						color: #fff;
						background-color: #3f51b5;
						box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
						cursor: pointer;
						padding: 6px 16px;
						font-size: 0.875rem;
						min-width: 64px;
						box-sizing: border-box;
						transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
						font-family: "Roboto", "Helvetica", "Arial", sans-serif;
						font-weight: 500;
						line-height: 1.75;
						border-radius: 4px;
						letter-spacing: 0.02857em;
						text-transform: uppercase;
						margin: 10px auto 0;

						&.disable {
							color: rgba(0, 0, 0, 0.26);
							box-shadow: none;
							background-color: rgba(0, 0, 0, 0.12);
							cursor: initial;
						}
					}
				}
			}
		}

		.r-r {
			.msg {
				width: 90%;
				display: inline-block;
				margin-top: 5px;
				text-align: left;

				li {
					display: flex;
					margin-bottom: 2px;

					svg {
						fill: currentColor;
						width: 1em;
						height: 1em;
						display: inline-block;
						font-size: 1.5rem;
						transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
						flex-shrink: 0;
						user-select: none;
					}

					span {
						margin-left: 5px;
						flex: 1;

					}
				}
			}
		}
	}

}

:where(.css-dev-only-do-not-override-19iuou).ant-btn-default {
	padding: 0;
	border: none;
}

:where(.css-dev-only-do-not-override-19iuou).ant-btn-default:not(:disabled):hover {
	color: inherit;
	border-color: transparent;
}

:where(.css-dev-only-do-not-override-19iuou).ant-dropdown {
	width: 360px;
}

:where(.css-dev-only-do-not-override-19iuou).ant-dropdown .ant-dropdown-menu {
	max-height: 300px;
	overflow: AUTO;
}

.ant-dropdown-menu-title-content {
	font-size: 1rem;
	line-height: 1.5;
	letter-spacing: 0.00938em;
}

.ant-dropdown-menu-title-content img {
	width: 30px;
}

@media (min-width:1900px) {
	.contactContent .r>div {
		width: 700px;
		height: 800px;
	}

	.contactContent .r .r-r .msg li span,
	.contactContent .r .r-l .des {
		font-size: 1.5rem;
	}
}

@media (max-width:1500px) {
	.contactContent .r>div {
		width: 48%;
	}
}

@media (max-width:1199px) {
	.contactContent .r {
		flex-direction: column;
	}

	.contactContent .r>div {
		margin: 50px 0;
		width: 500px;
	}
}

@media (max-width:1024px) {
	.contactContent .leftNav {
		display: none;
	}

	.contactContent .r>div {
		margin: 50px auto 0;
		max-width: 90%;
	}

	.contactContent .r {
		padding-bottom: 50px;
	}

	.contactContent .r .title {
		font-size: 1.5em;
	}
}

@media (max-width:550px) {
	.contactContent .r>div {
		width: 100%;
		margin-top: 10px;
	}

	.contactContent .r .r-r {
		margin-top: 30px;
	}

	.contactContent .r .r-l .content .des,
	.contactContent .r .r-l .content form .inputBox {
		width: 100%;
	}
}
</style>