// 导入axios库
import axios from 'axios'
// 导入Element UI的通知和消息组件
import { notification, message as Message } from 'ant-design-vue'
import router from '../router'
// import {  getToken } from '@/utils/localStorage'



// 设置axios的默认请求头
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: '//joyoussz.com/public/request',
    // 超时
    timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
    // 将请求体转换为JSON字符串
    config.data = JSON.stringify(config.data);
    config.headers.Authorization = localStorage.getItem('token'); 
    
    // 返回配置对象以便继续发送请求
    return config
}, error => {
    // 如果发生错误，打印错误信息并拒绝Promise
    console.log(error)
    return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code;
    // 获取错误信息
    const msg = res.data.msg
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
        return res.data
    }
    // 如果状态码为500，则显示错误消息并拒绝Promise
    if (code === 500) {
        Message.destroy();
        Message.error({content: () =>'error'});
        return Promise.reject(new Error(msg))
    } else if (code == 100) {  // 未登录
        Message.destroy();
        notification['error']({ message: msg, description: '' })
        router.push('/login')
        return Promise.reject('error')
    } else if (code == 0) { //错误
        console.log('eeer');
        Message.destroy();
        notification['error']({ message: msg, description: '' })
        return res.data
        // return Promise.reject('error')
    } else { // 如果状态码为200，则返回响应数据
        Message.destroy();
        return res.data
    }
},
    error => {
        // 错误处理
        console.log('err:' + error)
        let { message } = error;

        // 根据不同的错误类型，给出友好的提示信息
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message.destroy();
        Message.error({ content: () => message });
        return Promise.reject(error)
    }
)

// 导出配置好的axios实例
export default service
