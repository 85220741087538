<template>
	<div class="indexContent">
		<div class="banner">
			<swiper :spaceBetween="0" :centeredSlides="true" :autoplay="{
				delay: 2500,
				disableOnInteraction: false,
			}" :effect="'fade'" :pagination="{
		clickable: true,
	}" :modules="modules" class="mySwiper">

				<swiper-slide data-swiper-autoplay="10000" v-for="(item, index) in info.banner" :key="index">
					<img :src="item.thumb" alt="" v-if="item.type==1">
					<video :src="item.video" autoplay muted loop v-else></video>
				</swiper-slide>
				
			</swiper>
		</div>
		<div class="indexSelection1">
			<h1 class="indexTitle">{{ info.h1_title }}</h1>
			<div class="box-c">
				<a :href="item.url" class="item" v-for="(item,index) in info.h1_content" :key="index">
					<div class="pic">
						<img :src="item.image" alt="">
					</div>
					<div class="text-box">{{ item.title }}</div>
				</a>
			</div>
		</div>
		<div class="indexSelection2">
			<h1 class="indexTitle">{{ info.h2_title }}</h1>
			<div class="box-c">
				<div class="l">
					<div class="video">
						<iframe class="jss33" height="400" width="2"
							:src="info.h2_video" frameborder="0"
							allowfullscreen=""></iframe>
					</div>
				</div>
				<div class="r">
					<div class="text-box">
						{{ info.h2_msg }}
					</div>
					<a :href="info.h2_url" class="commonMore">
						<button>Go To Videos</button>
					</a>
				</div>
			</div>
		</div>
		<div class="indexSelection3">
			<h1 class="indexTitle">{{ info.h3_title }}</h1>
			<div class="text-box">
				{{ info.h3_msg }}
			</div>
			<a :href="info.h3_url" class="commonMore">
				<button>Read More About Us</button>
			</a>
		</div>
		<div class="indexSelection4">
			<h1 class="indexTitle">{{ info.h4_title }}</h1>
			<div class="box-c">
				<img :src="item" alt="" class="item" v-for="(item,index) in info.h4_images" :key="index">
			</div>
		</div>
		<div class="indexSelection3">
			<h1 class="indexTitle">{{ info.h5_title }}</h1>
			<div class="text-box">
				{{ info.h5_msg }}
			</div>
			<a :href="info.h5_url" class="commonMore">
				<button>Contact Us</button>
			</a>
		</div>
	</div>

	<!-- </div> -->
</template>

<script>
import {
	Swiper,
	SwiperSlide
} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './style.css';

// import required modules
import {
	EffectFade,
	Autoplay,
	Pagination,
	Navigation
} from 'swiper/modules';
import { home } from "@/utils/api"
export default {
	name: 'index',
	components: {
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			info: [],
		}
	},
	mounted() {
		this.getdata()
	},
	setup() {
		return {
			modules: [EffectFade, Autoplay, Pagination, Navigation],
		};
	},
	methods: {
		getdata() { 
			home().then(res => { 
				this.$data.info = res.data
			})
		}
	}
}
</script>

<style lang="less">
.indexContent {
	.banner {
		.swiper-slide {
			position: relative;
			height: auto;

			img,
			video {
				display: block;
				width: 100%;
			}

			video {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 3;
			}
		}
	}

	.indexTitle {
		color: #110884;
		font-size: 2vw;
		text-align: center;
		margin-bottom: 2%;
	}

	.commonMore {
		button {
			color: white;
			margin: 1rem;
			font-size: 1vw;
			background-color: #110884;
			padding: 6px 16px;
			min-width: 64px;
			box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
			box-sizing: border-box;
			transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			font-family: "Roboto", "Helvetica", "Arial", sans-serif;
			font-weight: 500;
			line-height: 1.75;
			border-radius: 4px;
			letter-spacing: 0.02857em;
			text-transform: uppercase;
			border: none;
			outline: none;
			cursor: pointer;

			&:hover {
				background-color: #110884;
				box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
			}
		}

	}

	.indexSelection1 {
		padding: 2% 5% 2% 5%;

		.box-c {
			display: flex;
			align-content: space-around;
			flex-direction: row;
			justify-content: space-around;

			.item {
				position: relative;

				img {
					width: 100%;
					height: 100%;
					margin: auto;
					display: block;
					opacity: 1;
					padding: 1%;
					transition: .5s ease;
					backface-visibility: hidden;

					&:hover {
						opacity: .3;
					}
				}

				.text-box {
					top: 85%;
					left: 50%;
					width: 75%;
					opacity: 0;
					position: absolute;
					transform: translate(-50%, -50%);
					text-align: center;
					transition: .5s ease;
					color: white;
					padding: 2% 4%;
					font-size: 1rem;
					background-color: #110884;

				}

				&:hover {
					.text-box {
						opacity: 1;
					}

				}
			}
		}
	}

	.indexSelection2 {
		padding: 2% 5%;
		text-align: center;
		background-color: #F0F0F5;

		.indexTitle {
			margin-bottom: 0;
		}

		.box-c {
			display: flex;
			padding: 2rem 0;
			flex-direction: row;
			justify-content: center;

			.l {

				width: 100%;
				flex-basis: 50%;

				.video {
					position: relative;
					padding-top: 56%;

					iframe {
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						position: absolute;
						display: block;
						object-fit: cover;
					}
				}

			}

			.r {
				display: flex;
				flex-basis: 30%;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				.text-box {
					width: 75%;
					margin: 1rem;
					font-size: 1vw;
					font-weight: normal;
				}
			}
		}
	}

	.indexSelection3 {
		padding: 3rem 0;
		text-align: center;

		.indexTitle {
			margin-bottom: 0;
		}

		.text-box {
			width: 50%;
			margin: 1rem auto;
			font-size: 1vw;
			font-weight: normal;
		}

		.commonMore {
			button {
				margin: 0 auto;
			}
		}
	}

	.indexSelection4 {
		padding: 2% 5% 2% 5%;
		background-color: #F0F0F5;

		.box-c {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-content: space-around;
			flex-direction: row;
			justify-content: space-around;

			.item {
				width: 15%;
				margin: auto;
				display: block;
			}

			.item1 {
				width: 10%;
			}
		}
	}
}

@media (max-width:1400px) {
	.indexContent .indexTitle {
		font-size: 2.5vw;
	}

	.indexContent .indexSelection2 .box-c {
		flex-direction: column;
	}

	.indexContent .indexSelection2 .box-c .r .text-box {
		width: 95%;
		font-size: 1.5vw;
	}

	.indexContent .commonMore button {
		font-size: 2vw;
	}

	.indexContent .indexSelection3 .text-box {
		font-size: 1.5;
		width: 95%;
	}
}

@media (max-width:1000px) {
	.indexContent .indexSelection3 .text-box {
		font-size: 2.5vw;
	}

	.indexContent .indexTitle {
		font-size: 3.5vw;
	}

	.indexContent .indexSelection2 .box-c .r .text-box {
		font-size: 2.5vw;
	}
}

@media (max-width:600px) {
	.indexContent .indexSelection3 .text-box {
		font-size: 3.5vw;
	}

	.indexContent .indexTitle {
		font-size: 4.5vw;
	}

	.indexContent .indexSelection2 .box-c .r .text-box {
		font-size: 3.5vw;
	}

	.indexContent .commonMore button {
		font-size: 3vw;
	}

	.indexContent .indexSelection2 {
		padding: 5vw 0 2vw;
	}

	.indexContent .indexSelection3,
	.indexContent .indexSelection1 {
		padding-top: 5vw;
		padding-bottom: 5vw;
	}

	.indexContent .indexSelection2 .box-c {
		padding: 1em 0 0;
	}

	.indexContent .commonMore button {
		margin-top: .3em;
	}
}
</style>