import request from '@/utils/request'

//公共调用
export function conn() {
    return request({
        url: '/index/index',
        method: 'get'
    })
}
//首页
export function home() {
    return request({
        url: '/index/home',
        method: 'get'
    })
}
//案例
export function caselist() {
    return request({
        url: '/info/case',
        method: 'get'
    })
}
//关于我们
export function about() {
    return request({
        url: '/info/about',
        method: 'get'
    })
}
//联系我们
export function contact() {
    return request({
        url: '/info/contact',
        method: 'get'
    })
}
//产品列表
export function productlists(query) {
    return request({
        url: '/product/index',
        method: 'get',
        params:query
    })
}
//产品详情
export function productdetail(query) {
    return request({
        url: '/product/details',
        method: 'get',
        params:query
    })
}
//表单
export function form(data) {
    return request({
        url: '/form/feedback',
        method: 'post',
        data:data
    })
}