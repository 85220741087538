<template>
	<div class="aboutContent">
		<leftNav></leftNav>
		<div class="r">
			<div class="pic">
				<img :src="info.thumb" alt="">
			</div>
			<div class="text-box">
				<p class="text-title">{{ info.title }}</p>
				<p class="text-des">
					{{ info.content }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import leftNav from './leftNav.vue'
import { about } from "@/utils/api"
export default {
	name: 'about',
	data() {
		return {
			info: [],
		}
	},
	mounted() {
		this.getdate()
	},
	components: {
		leftNav,
	},
	methods: {
		getdate() {
			about().then(res => {
				this.$data.info = res.data;
			})
		}
	}
}
</script>

<style lang="less">
.aboutContent {
	display: flex;
	// justify-content: space-between;
	padding-top: 20px;
	padding-bottom: 12px;

	.r {
		flex: 1;
		margin: 30px 10px 30px 10px;
		max-width: 1800px;
		background-color: rgb(255, 255, 255);
		color: rgba(0, 0, 0, 0.87);
		box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
		transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
		border-radius: 4px;
		overflow: hidden;
	}

	.pic {
		img {
			display: block;
			width: 100%;
		}
	}

	.text-box {
		padding: 16px 16px 24px;

		.text-title {
			margin: 0px 0px 0.35em;
			font-family: Roboto, Helvetica, Arial, sans-serif;
			font-weight: 400;
			font-size: 1.5rem;
			line-height: 1.334;
			letter-spacing: 0em;
		}

		.text-des {
			margin: 0px;
			font-family: Roboto, Helvetica, Arial, sans-serif;
			font-weight: 400;
			line-height: 1.43;
			letter-spacing: 0.01071em;
			font-size: 1.2rem;
		}
	}
}

@media(max-width:1024px) {
	.aboutContent .r {
		margin: 20px 10px;
	}

	.aboutContent .leftNav {
		display: none;
	}

	.aboutContent .text-box .text-des {
		font-size: 1em;
	}
}

@media(max-width: 500px) {
	.aboutContent .r {
		margin: 10px 10px;
	}

	.aboutContent .text-box .text-title {
		font-size: 1.2em;
	}

	.aboutContent .text-box .text-des {
		font-size: .9em;
	}
}
</style>