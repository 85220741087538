<template>
	<div class="footer">
		<div class="footer-t">
			<div class="item">
				<div class="text-box">
					<a href="/" class="text-title">
						<h2>Trastar Inc.</h2>
					</a>
					<div class="line"></div>
					<div class="text-des">
						<p>{{ info.address }}</p>
						<p>TEL: {{ info.tel }}</p>
						<p>FAX: {{ info.fax }}</p>
						<p>{{ info.email }}</p>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="text-box">
					<a href="#/product" class="text-title">
						<h2>Products</h2>
					</a>
					<div class="line"></div>
					<div class="text-des">
						<a :href="'#/product?id=' + item.id" v-for="(item, index) in bpronav" :key="index">{{ item.catname }}</a>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="text-box">
					<div class="text-title">
						<h2>Information</h2>
					</div>
					<div class="line"></div>
					<div class="text-des">
						<a href="{{item.url}}" v-for="(item, index) in navbot" :key="index">{{ item.catname }}</a>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-b">
			<div class="l">{{ info.copyright }}</div>
			<div class="c"><a href="">Privacy Policy</a></div>
			<div class="r"><a href="">Terms of Service</a></div>
		</div>
	</div>
</template>

<script>
import { info } from 'sass';

export default {
	name: 'footer',
	props: {
		msg: String
	},
	data() {
		return {
			info: [],
			navbot: [],
			bpronav: [],
		}
	},
	mounted() {
		this.$data.info = JSON.parse(localStorage.getItem('info'));
		this.$data.navbot = JSON.parse(localStorage.getItem('navbot'));
		this.$data.bpronav = JSON.parse(localStorage.getItem('bpronav'));
	},
}
</script>

<style lang="less">
.footer {
	background-color: #DADAE1;

	.footer-t {
		display: flex;
		align-content: space-around;
		justify-content: space-around;

		.item {
			.text-box {
				margin: 20px;
				text-align: left;

				.text-title {
					font-size: 16px;
					color: #110884;
				}

				.line {
					width: 150px;
					height: 1.5px;
					margin: 2px 0px 4px 0px;
					border-width: 0;
					background-color: #110884;
				}

				.text-des {

					p,
					a {
						color: #000;
						margin: 2px;
						display: flex;
						align-items: center;
						flex-direction: row;
						line-height: 24px;
					}
				}
			}
		}
	}

	.footer-b {
		line-height: 30px;
		border-top: solid 1px black;
		display: flex;
		flex-wrap: wrap;
		align-content: space-around;
		justify-content: space-around;
		font-size: 12px;
		color: #000;

		a {
			color: #000;
		}

		.l {
			width: 245px;
		}

		.c {
			width: 228px;
		}

		.r {
			width: 150px;
		}
	}
}

@media (max-width:650px) {

	.footer .footer-b,
	.footer .footer-t {
		flex-wrap: wrap;
		align-content: center;
		flex-direction: column;
		justify-content: center;
	}

	.footer .footer-t .item {
		width: 100%;
	}

	.footer .footer-b {
		padding: 5px 20px;
	}

	.footer .footer-b .l,
	.footer .footer-b .c,
	.footer .footer-b .r {
		width: 100%;
		line-height: 20px;
	}

	.footer .footer-t {
		padding: 20px 0;
	}

	.footer .footer-t .item:not(:last-child) {
		margin-bottom: 10px;
	}

	.footer .footer-t .item .text-box {
		margin: 0;
		padding: 0 20px;
	}
}
</style>