<template>
	<div class="productContent">
		<leftNav @pronavlink="handlePronavlink"></leftNav>
		<div class="productSelection">
			<div class="productBread">
				<div>
					<RouterLink to="">All Products</RouterLink>
				</div>
				<!-- <div class="line">/</div> -->
				<!-- <div class="current">Area Lighting</div> -->
			</div>
			<!-- <div class="productDetails" v-show="type==1"> -->
			<div class="productDetails" v-if="type == 1">
				<div class="productDetailsBox1">
					<div class="box-c">
						<div class="l">
							<swiper :observer="true" :direction="'vertical'" @swiper="setThumbsSwiper" :spaceBetween="5"
								:slidesPerView="8" :freeMode="true" :watchSlidesProgress="true" :modules="modules"
								class="galleryThumbs">
								<swiper-slide v-for="(item,index) in info.image"><img :src="item" /></swiper-slide>
								<swiper-slide v-for="(item,index) in info.video"><img src="../assets/h2ohfs1vk3e7cazyo8kv.png" /></swiper-slide>
							</swiper>
							<swiper :observer="true" :spaceBetween="10" :thumbs="{ swiper: thumbsSwiper }"
								:modules="modules" class="galleryTop">
								<swiper-slide v-for="(item,index) in info.image"><img :src="item" /></swiper-slide>
								<swiper-slide v-for="(item,index) in info.video">
									<iframe width="350" height="350"
										:src="item.url"
										frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen="" title="Embedded YouTube"></iframe>
								</swiper-slide>
							</swiper>

						</div>
						<div class="r">
							<h5 class="title">{{ info.title }}</h5>
							<div class="r-nav">
								<div class="navItem active " v-wave @click="switchNav($event)">Info</div>
								<div class="navItem " v-wave @click="switchNav($event)">Technical</div>
							</div>
							<div class="r-c">
								<div class="item item1 active">
									<h6 class="text-box">
										<p style="white-space: pre-wrap;">{{ info.info }}</p>
									</h6>
								</div>
								<div class="item item2">
									<table>
										<tr>
											<th class="table-header">Files</th>
										</tr>
										<tr v-for="(item, index) in info.download" :key="index">
											<th class="table-body">
												<a :href="item.file" target="_blank" class="link">
													<img src="../assets/pdf.png" alt="">
													<span class="link-text">{{ item.title }}</span>
												</a>
											</th>
										</tr>

									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="productList" v-else>
				<!-- <div class="productList" v-show="type==0"> -->
				<ul class="list">
					<li class="item" v-for="(item, index) in lists" :key="index">
						<RouterLink :to="'/product?type=1&id=' + item.id" v-wave>
							<div class="pic">
								<img :src="item.thumb" alt="">
							</div>
							<div class="text-box">
								<div class="text-title">{{ item.title }}</div>
							</div>
						</RouterLink>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery';
import {
	Swiper,
	SwiperSlide
} from 'swiper/vue';
import {
	ref
} from 'vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import {
	FreeMode,
	Navigation,
	Thumbs
} from 'swiper/modules';
import leftNav from './leftNav.vue'
import { productlists, productdetail } from "@/utils/api"
export default {
	name: 'App',
	components: {
		leftNav,
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			type: 0,
			id: 0,
			lists: [],
			info: []
		}
	},
	setup() {
		const thumbsSwiper = ref(null);

		const setThumbsSwiper = (swiper) => {
			thumbsSwiper.value = swiper;
		};

		return {
			thumbsSwiper,
			setThumbsSwiper,
			modules: [FreeMode, Navigation, Thumbs],
		};
	},
	// onBeforeUnmount(() => {
	//    if (thumbsSwiper.value) {
	//        thumbsSwiper.value.swiper.destroy();
	//    }
	// },	
	methods: {
		switchNav(event) {
			// console.log(event)
			$(event.target).addClass("active").siblings().removeClass("active")
			$(".productDetailsBox1 .box-c .r .r-c .item").eq($(event.target).index()).addClass("active").siblings().removeClass("active")
		},
		handlePronavlink(id) {
			this.prolist(id);
		},
		prolist(id) {
			let param = { catid: id }
			productlists(param).then(res => {
				this.$data.lists = res.data.lists;
			})
		},
		detail() {
			let param = { id: this.$data.id }

			productdetail(param).then(res => {
				this.$data.info = res.data.info;
			})
		}

	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				// console.log(111)
				this.type = this.$route.query.type ? this.$route.query.type : 0;
				this.id = this.$route.query.id ? this.$route.query.id : 0;
				console.log(this.id)
				const thumbsSwiper = ref(null);
				if (this.type) {
					this.detail();
				}

				const setThumbsSwiper = (swiper) => {
					thumbsSwiper.value = swiper;
				};
				this.thumbsSwiper = thumbsSwiper
				this.setThumbsSwiper = setThumbsSwiper
			}
		}
	},
	mounted() {
		let type = this.$route.query.type; // John
		this.type = type ? type : this.type;
		this.id = this.$route.query.id;

		this.prolist(3);
	}
}
</script>

<style lang="less">
.productContent {
	display: flex;
	padding-top: 20px;
	padding-bottom: 12px;

	.productSelection {
		width: 100%;

		.productBread {
			display: flex;
			color: rgba(0, 0, 0, 0.54);
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			letter-spacing: 0.00938em;

			a {
				color: rgba(0, 0, 0, 0.54);

				&:hover {
					text-decoration: underline;
				}
			}

			.current {
				color: rgba(0, 0, 0, 0.87);
			}

			.line {
				margin: 0 8px;
			}
		}

		.productList {
			.list {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;

				.item {
					padding: 12px;

					a {
						display: block;
						width: 275px;
						color: rgba(0, 0, 0, 0.87);
						transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
						background-color: #fff;
						border-radius: 4px;
						box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
						transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

						&:hover {
							background-color: rgba(0, 0, 0, 0.04);
						}

						.pic {
							img {
								display: block;
								width: 100%;
							}
						}

						.text-box {
							padding: 16px;

							.text-title {
								display: -webkit-box;
								overflow: hidden;
								font-size: 1rem;
								text-overflow: ellipsis;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 4;
								color: #000;
								height: 5em;

							}
						}
					}
				}
			}
		}
	}

	.productDetails {
		padding-top: 20px;

		.productDetailsBox1 {
			margin: 10px 10px 10px;
			padding: 16px;
			padding-bottom: 170px;
			box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

			.box-c {
				display: flex;

				.l {
					// padding: 8px;
					display: flex;
					justify-content: space-between;
					width: 400px;
					height: 400px;

					.galleryThumbs {
						height: auto;
						width: 12.5%;
						object-fit: cover;
						margin: 0px 1%;
						height: 350px;

						.swiper-slide {
							width: 100%;
							height: auto;
							opacity: .4;
							border: 1px solid #000;
							cursor: pointer;

							&.swiper-slide-thumb-active {
								opacity: 1;
							}
						}
					}

					.galleryTop {
						width: 100%;
						height: 350px;
					}

					.swiper-slide {
						img {
							display: block;
							width: 100%;
							height: 100%;
							object-fit: cover;



						}
					}
				}

				.r {
					padding: 0px 8px 8px 16px;
					flex: 1;

					.title {
						font-size: 1.5rem;
						font-weight: 400;
						line-height: 1.334;
						margin-bottom: .35em;
					}

					.r-nav {
						display: flex;
						background-color: #3f51b5;
						box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

						span {
							position: relative;
							z-index: 4;
						}

						.navItem {
							position: relative;
							flex: 1;
							line-height: 48px;
							font-size: 0.875rem;
							letter-spacing: 0.02857em;
							text-transform: uppercase;
							color: #fff;
							text-align: center;
							cursor: pointer;
							opacity: .7;
							transition: all .6s;

							&::before {
								position: absolute;
								content: '';
								left: 50%;
								height: 3px;
								width: 0;
								bottom: 0;
								transform: translateX(-50%);
								background-color: #e03d77;
								transition: all .6s;
							}

							&.active {
								opacity: 1;
							}

							&.active {
								&::before {
									width: 100%;
								}

							}
						}
					}

					.r-c {
						.item {
							padding: 24px;
							display: none;

							&.active {
								display: block;
							}

							&.item1 {
								.text-box {
									font-size: 1rem;
									font-family: "Roboto", "Helvetica", "Arial", sans-serif;
									font-weight: 400;
									line-height: 1.75;
									letter-spacing: 0.00938em;
									margin-bottom: .35em;

									p {
										margin-bottom: 0;
									}
								}
							}

							&.item2 {
								table {
									width: 100%;
									display: table;
									border-spacing: 0;
									border-collapse: collapse;
									box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
									border-radius: 4px;
									color: rgba(0, 0, 0, 0.87);
									transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
									background-color: #fff;

									tr {
										display: table-row;
										outline: 0;
										vertical-align: middle;

										th {
											display: table-cell;
											padding: 16px;
											font-size: 0.875rem;
											text-align: left;
											font-family: "Roboto", "Helvetica", "Arial", sans-serif;
											font-weight: 400;
											line-height: 1.43;
											border-bottom: 1px solid rgba(224, 224, 224, 1);
											letter-spacing: 0.01071em;
											vertical-align: inherit;
										}

										.table-body {
											.link {
												display: flex;
												align-items: center;
												flex-direction: row;

												.link-text {
													color: #0000ee;
													text-decoration: underline;
													flex: 1;
													margin-left: 5px;
												}
											}
										}
									}
								}
							}
						}
					}
				}

			}
		}
	}
}

@media (max-width:1199px) {
	.productContent .productDetails .productDetailsBox1 .box-c {
		flex-direction: column;
	}

	.productContent .productDetails .productDetailsBox1 .box-c .r {
		width: 100%;
	}
}

@media (max-width:1024px) {
	.productContent {
		flex-direction: column;
	}

	.productContent .productSelection {
		padding: 0 20px 20px;
	}

	.productContent .productDetails .productDetailsBox1 .box-c .l {
		height: auto;
	}

	.productContent .productDetails .productDetailsBox1 .box-c .r {
		padding-left: 0;
		margin-top: 20px;
	}

	.productContent .productDetails .productDetailsBox1 {
		padding-bottom: 60px;
	}
}

@media (max-width: 768px) {
	.productContent .productSelection .productList .list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	.productContent .productSelection .productList .list .item a {
		width: 100%;
	}

	.productContent .productDetails .productDetailsBox1 {
		padding-bottom: 50px;
	}

	.productContent .productDetails .productDetailsBox1 .box-c .r .r-c .item {
		padding: 15px;
	}
}

@media (max-width:550px) {
	.productContent .productDetails .productDetailsBox1 .box-c .l {
		width: 300px;
		height: 261px;
	}

	.productContent .productDetails .productDetailsBox1 .box-c .l .galleryTop,
	.productContent .productDetails .productDetailsBox1 .box-c .l .galleryThumbs {
		height: 100%;
	}
}

@media (max-width:500px) {
	.productContent .productDetails .productDetailsBox1 .box-c .r .r-nav .navItem {
		line-height: 40px;
	}

	.productContent .productSelection {
		padding: 0 10px 20px;
	}

	.productContent .productSelection .productList .list .item {
		padding: 6px;
	}

	.productContent .productDetails .productDetailsBox1 {
		padding-bottom: 40px;
	}

	.productContent .productDetails .productDetailsBox1 .box-c .r .r-c .item.item2 table tr th {
		padding: 8px;
	}
}
</style>