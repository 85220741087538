<template>
	<div class="casesContent">
		<leftNav></leftNav>
		<div class="caseVideo">
			<div class="item" v-for="(item,index) in lists" :key="index">
				<div class="video">
					<iframe class="jss90" height="400" width="2"
						:src="item.video" frameborder="0"
						allowfullscreen=""></iframe>
				</div>
				<div class="text-box">
					<p class="text-title">{{ item.title }}</p>
					<p class="text-des">{{ item.msg }}</p>
					<a :href="item.url" class="commonMore">
						<button>Go to Product</button>
					</a>
				</div>
			</div>
			 
		</div>
	</div>
</template>

<script>
import { caselist } from "@/utils/api"
import leftNav from './leftNav.vue'
export default {
	name: 'cases',
	data() {
		return {
			lists: [],
		}
	},
	components: {
		leftNav,
	},
	mounted() {
		this.getdate()
	},
	methods: {
		getdate() {
			caselist().then(res => {
				this.$data.lists = res.data.lists;
			})
		}
	}
}
</script>

<style lang="less">
.casesContent {
	display: flex;
	// justify-content: space-between;
	padding-top: 20px;
	padding-bottom: 12px;

	.caseVideo {
		margin: 20px;
		width: 1200px;
		max-width: 100%;

		.item {
			margin-bottom: 2em;
			box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
			overflow: hidden;
			border-radius: 4px;

			.video {
				position: relative;
				padding-top: 56%;

				iframe {
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					position: absolute;
				}
			}

			.text-box {
				padding: 16px 16px 24px;

				.text-title {
					font-size: 2rem;
					font-weight: 400;
					line-height: 1.5;
					letter-spacing: 0.00938em;
					margin-bottom: .35em;
				}

				.text-des {
					font-weight: 400;
					line-height: 1.5;
					letter-spacing: 0.00938em;
					margin-bottom: .35em;
					font-size: 1.2em;
				}

				.commonMore {
					button {
						color: white;
						margin: 1rem 0 0 0;
						font-size: 0.875rem;
						background-color: #110884;
						padding: 6px 16px;
						min-width: 64px;
						box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
						box-sizing: border-box;
						transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
						font-family: "Roboto", "Helvetica", "Arial", sans-serif;
						font-weight: 500;
						line-height: 1.75;
						border-radius: 4px;
						letter-spacing: 0.02857em;
						text-transform: uppercase;
						border: none;
						outline: none;
						cursor: pointer;

						&:hover {
							background-color: #110884;
							box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
						}
					}

				}
			}
		}
	}
}

@media (max-width:1024px) {
	.casesContent .leftNav {
		display: none;
	}

	.casesContent {
		flex-direction: column;
	}

	.casesContent .caseVideo {
		width: 100%;
		margin: 0;
		padding: 20px;
	}

	.casesContent .caseVideo .item .text-box .text-title {
		font-size: 1.5em;
	}

	.casesContent .caseVideo .item .text-box .text-des {
		font-size: 1em;
	}
}

@media (max-width:500px) {
	.casesContent .caseVideo {
		padding: 10px;
	}

	.casesContent .caseVideo .item .text-box .text-title {
		font-size: 1.3em;
	}

	.casesContent .caseVideo .item .text-box .text-des {
		font-size: .9em;
	}

	.casesContent .caseVideo .item .text-box .commonMore button {
		margin-top: .5em;
	}
}
</style>