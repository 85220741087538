import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import index from '../components/index.vue'
import product from '../components/product.vue'
import cases from '../components/cases.vue'
import contact from '../components/contact.vue'
import about from '../components/about.vue'
import careers from '../components/careers.vue'
const routes = [{
		path: '/',
		component: index
	},
	{
		path: '/index',
		component: index
	},
	{
		path: '/product',
		component: product,
	},
	{
		path: '/cases',
		component: cases,
		
	},
	{
		path: '/about',
		component: about,
		
	},
	{
		path: '/contact',
		component: contact,
		
	},
	{
		path: '/careers',
		component: careers,
		
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

export default router