<template>
	<div class="header">
		<div class="aslide" @click="showAslideMOdal">
			<svg class="MuiSvgIcon-root jss8 MuiSvgIcon-fontSizeLarge" focusable="false" viewBox="0 0 24 24"
				aria-hidden="true">
				<path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
			</svg>
		</div>
		<div class="logo">
			<a href="#/">
				<img :src="info.logo" alt="">
			</a>
		</div>

		<ul class="nav">
			<li v-wave><a href="#/">Home</a></li>
			<li v-wave v-for="(item, index) in menu" :key="index"><a :href="item.url">{{ item.catname }}</a></li>
		</ul>

		<div class="aslideModal" :class="isShowAslideMOdal ? 'active' : ''">
			<div class="shadow" @click="hideAslideMOdal"></div>
			<div class="aslideModalContent">
				<ul class="list">
					<li><a href="#/">Home</a></li>
					<li><a href="#/product">Products</a></li>
					<li><a href="#/cases">Case Studies</a></li>
					<li><a href="#/about">About</a></li>
					<li><a href="#/contact">Contact</a></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { conn } from "@/utils/api"
export default {
	name: 'head',
	props: {
		msg: String
	},
	data() {
		return {
			info: [],
			menu: [],
			isShowAslideMOdal: false
		}
	},
	mounted() {
		this.getconn();
		this.$data.info = JSON.parse(localStorage.getItem('info'));
		this.$data.menu = JSON.parse(localStorage.getItem('navlist'));
	},
	methods: {
		getconn() {
			conn().then(res => {
				localStorage.setItem('navlist', JSON.stringify(res.data.navlist));
				localStorage.setItem('prolist', JSON.stringify(res.data.prolist));
				localStorage.setItem('navbot', JSON.stringify(res.data.navbot));
				localStorage.setItem('info', JSON.stringify(res.data.info));
				localStorage.setItem('bpronav', JSON.stringify(res.data.bpronav));
			})
		},
		showAslideMOdal() {
			this.isShowAslideMOdal = true
		},
		hideAslideMOdal() {
			this.isShowAslideMOdal = false
		}
	}
}
</script>

<style>
.header {
	display: flex;
	position: sticky;
	top: 0;
	max-width: 100%;
	align-items: center;
	/* margin-bottom: 20px; */
	flex-direction: row;
	background-color: white;
	z-index: 999;
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.header .aslide {
	display: none;
}

.header .aslide svg {
	fill: black;
	cursor: pointer;
	margin-left: 5px;
	font-size: 2.1875rem;
	width: 1em;
	height: 1em;
	display: inline-block;
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	flex-shrink: 0;
	user-select: none;
}

.header .logo img {
	max-width: 75%;
}

.header .nav {
	min-height: 64px;
	display: flex;
	align-items: flex-end;
	padding-left: 24px;
	padding-right: 24px;
	margin-bottom: 0;
}

.header .nav li {
	color: rgba(0, 0, 0, 0.87);
	padding: 6px 8px;
	font-size: 0.875rem;
	min-width: 64px;
	box-sizing: border-box;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 500;
	line-height: 1.75;
	border-radius: 4px;
	letter-spacing: 0.02857em;
	text-transform: uppercase;
}

.header .nav li>a {
	color: rgb(130, 130, 130);
	margin: 0 1rem;
	font-size: 20px;
	font-weight: bold;
	white-space: nowrap;
	text-decoration: none;
}

.header .nav li.active,
.header .nav li:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

.aslideModal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;

	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: all .6s;
}

.aslideModal.active {
	opacity: 1;
	visibility: visible;
	pointer-events: inherit;
}

.aslideModal .shadow {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	z-index: -1;
	opacity: 0;
	position: fixed;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-tap-highlight-color: transparent;
}

.aslideModal.active .shadow {
	opacity: 1;
	transition: opacity .6s cubic-bezier(0.4, 0, 0.2, 1);
}

.aslideModal .aslideModalContent {
	height: 100%;
	top: 0;
	left: 0;
	transform: translateX(-100%);
	display: flex;
	outline: 0;
	z-index: 1200;
	position: absolute;
	overflow-y: auto;
	flex-direction: column;
	box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
	color: rgba(0, 0, 0, 0.87);
	background-color: #fff;
	transition: all .6s cubic-bezier(0, 0, 0.2, 1);
}

.aslideModal.active .aslideModalContent {
	transform: translateX(0%);
	/* transform: none; */

}

.aslideModal .aslideModalContent .list {
	padding-top: 8px;
	padding-bottom: 8px;
	width: 250px;
}

.aslideModal .aslideModalContent .list li {
	transition: background-color .6s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.aslideModal .aslideModalContent .list li:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

.aslideModal .aslideModalContent .list li>a {
	display: block;
	padding: 8px 16px;
	line-height: 32px;
	color: black;
}

@media (max-width:1199px) {
	.header .nav {
		display: none;
	}

	.header .aslide {
		display: block;
	}

	.header .logo {
		width: 100%;
		display: flex;
		align-content: center;
		justify-content: center;
	}

	.header .logo img {
		margin: 0 auto 0 auto;
		display: block;
	}
}

@media (max-width:500px) {
	.header .aslide svg {
		transform: scale(.75);
	}
}
</style>