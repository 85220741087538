<template>
	<div class="main">
		<Head></Head>
		<div class="container">
			<RouterView />
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import foot from './components/foot.vue';
	import Head from './components/head.vue'
	export default {
		name: 'App',
		components: {
			Head,foot
		}
	}
</script>

<style>
	@font-face {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		src: url(./assets/fonts/51tng-211ow.ttf);
		
	}
	* {
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;
		font-family: 'Roboto', sans-serif;
	}

	html>#root>div {
		width: 100vh;
		height: 100vh;
	}

	html {
		height: 100vh;
	}

	body {
		position: relative;
		min-height: 100%;
		min-height: 100vh;
		border-bottom: .0000000000000000000000000000000000000001px transparent solid;

	}

	ul {
		list-style: none;
	}

	a {
		text-decoration: none;
	}
	.main{
		min-height: 100vh;
		display: flex;
		flex-direction: column;
	}
	.container{
		flex:1
	}
	
</style>